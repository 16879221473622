.container {
  background-color: var(--backgroundDark);
  color: var(--textColorLight);
  padding: 1.5rem;
  border-radius: 1rem;
  position: absolute;
  top: -45px;
  left: 50%;
  max-width: 34.5rem;
  width: 100%;
  transform: translateX(-50%);
}

.header {
  font-size: 4.8rem;
  font-weight: 300;
  line-height: 4.8rem;
  margin-bottom: 0.7rem;
}

.subtitle {
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 1.8rem;
}

.link {
  border: 1px solid white;
  border-radius: 0.5rem;
  padding: 0.8rem 1.5rem;
  display: inline-block;
}

@media screen and (min-width: 768px) {
  .container {
    left: unset;
    transform: translateX(0%);
    right: 0;
    max-width: 31.6rem;
  }
  
}

@media screen and (min-width: 1024px) {
  .container {
    position: relative;
    top: unset;
    margin-bottom: 3.2rem;
  }
}
