.title {
  font-size: 4rem;
  line-height: 4rem;
  font-weight: 400;
  color: var(--headerPrimary);
  margin: 2rem 0;
  font-size: 4rem;
  line-height: 4rem;
  font-weight: 400;
  color: var(--headerPrimary);
  margin: 2rem 0;
}

.titleSection {
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
}

.wrapper {
  width: 100%;
  height: 18rem;
  position: relative;
  width: 100%;
  height: 13rem;
  position: relative;
}

@media screen and (min-width: 768px) {
  .titleSection {
    flex-direction: row-reverse;
  }

  .title {
    width: 50%;
  }

  .wrapper {
    width: 50%;
    height: 15rem;
  }
}

@media screen and (min-width: 1024px) {
  .titleSection {
    display: block;
  }
  .title {
    width: 100%;
    padding-top: 3rem;
  }
  @media screen and (min-width: 1024px) {
    .titleSection {
      display: block;
    }
    .title {
      width: 100%;
      padding-top: 3rem;
    }
  }
}
