.imageContainer {
    position: absolute;
}

.videoContainer {
    position: relative;
    border-radius: 1rem;
    overflow: hidden;
    aspect-ratio: 1.8 / 1;
}

.videoContainer:hover {
    cursor: pointer;
}

.youtubeIcon {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    pointer-events: none;
}

.youtube {
    width: 54px;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.youtube:hover {
    cursor: pointer;
}

.video {
    margin-bottom: 3rem;
    position: relative;
}

.image {
    width: 100%;
    aspect-ratio: 1.6/1;
    object-fit: cover;
}
