.row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

}

.price {
    font-size: 2.3rem;
    line-height: 0;
    color: var(--textHighlight);
}

.month {
    font-size: 1.2rem;
    color: var(--textHighlight);
}

.top {
    padding: 2rem 0;
    border-top: 1px solid rgba(198, 198, 198, 0.4);
    border-bottom: 1px solid rgba(198, 198, 198, 0.4);
}

.rowMargin {
    margin-bottom: 0.8rem;
}

.wrapper {
    max-width: 40rem;
}

.text {
    font-size: 1.4rem;
    color: var(--textColorDark);
}

.textBold {
    font-weight: 600;
}

.bottom {
    padding: 2rem 0;
    border-bottom: 1px solid rgba(198, 198, 198, 0.4);
}

.wrapperBottom {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 50rem;
}

.summaryText {
    font-size: 1.6rem;
    color: #515252;
}

.summaryItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.summary {
    margin-bottom: 3rem;
}

.superscript {
    font-size: 1rem;
}

.success {
    border-top: 1px solid rgba(198, 198, 198, 0.4);
    padding: 2rem 0;
}

.successText {
    display: inline-block;
    background-color: var(--textHighlight);
    color: white;
    padding: 0.8rem 1.5rem;
    border-radius: 15px;
}