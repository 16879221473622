.error {
    display: flex;
    gap: 18rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100vw;
    padding-top: 15rem;
}

.image {
    width: 7rem;
    margin-bottom: 3rem;
}

.header {
    font-size: 4rem;
    line-height: 4rem;
    font-weight: 400;
    color: var(--headerPrimary);
    margin-bottom: 2rem;
}

.text {
    color: var(--textColorDark);
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 2rem;
}

.link {
    border: 1px solid var(--textColorDark);
    border-radius: 0.5rem;
    padding: 0.8rem 1.5rem;
    display: inline-block;
    font-size: 1.4rem;
}

@media screen and (min-width:1024px) {
    .error {
        display: grid;
        grid-template-columns: 1fr 31.6rem;
        padding-top: 0;
        gap: 2rem;
    }
    .right {
        width: 31.6rem;
    }

}