.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.7rem;
}

.image {
    object-fit: cover;
    width: 100%;
    height: 100%;
}


@media screen and (min-width: 768px) {
    .container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
}
