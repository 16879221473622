.itemContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 2.4rem;
}

.active {
    color: #515252;
}

.notActive {
    color: #515252;
    opacity: 0.3;
    text-decoration: line-through;
}