:root {
  --bodyBackground: #fff;
  --headerPrimary: #646464;
  --headerSecondary: #515252;
  --textColorDark: #414141;
  --textColorLight: #fff;
  --textHighlight: #d10023;
  --backgroundDark: rgba(25, 34, 67, 1);
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bodyBackground);
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2 {
  font-weight: 600;
  color: var(--headingsColor);
}

p {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.6rem;
}

li {
  list-style: none;
}

.sitePadding {
  padding-left: 1.4rem;
  padding-right: 1.5rem;
}

.main {
  position: relative;
}

.headerSmall {
  font-size: 1.8rem;
  color: var(--headerSecondary);
  font-weight: 600;
  margin-bottom: 1.7rem;
}

.sectionMarginBottom {
  margin-bottom: 3rem;
}

.loadingScreen {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingHeader{
  font-size: 4rem;
  text-transform: uppercase;
  color: #414141;
}

@media screen and (min-width: 768px) {
  .sitePadding {
    padding-left: 1.7rem;
    padding-right: 2.4rem;
  }
  .videoMapWrapper {
    display: flex;
    gap: 1.6rem;
    justify-content: space-between;
  }

  .videoMapWrapper > div {
    width: 50%;
  }
}

@media screen and (min-width: 1024px) {
  .main {
    display: flex;
    margin: auto;
    gap: 1.6rem;
    max-width: 98rem;
  }
  .left {
    width: 62.8rem;
  }
  .right {
    width: 31.6rem;
    position: absolute;
    right: 0;
  }

  .rightTopLG {
    top: -11rem;
  }

  .rightTopSM {
    top: 4rem;
  }
}
