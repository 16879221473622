.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem 0;
}

.itemContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.active {
    color: #515252;
}

.notActive {
    color: #515252;
    opacity: 0.3;
    text-decoration: line-through;
}

@media screen and (min-width:768px) {
    .container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    } 
}