.image {
    width: 100%;
    height: 29rem;
    object-fit: cover;
}

.noImage {
    height: 4rem;
}

@media screen and (min-width:768px) {
    .image {
        height: 46rem;
    }
}

