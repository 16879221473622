.imageContainer {
  border: 2px solid #898989;
  border-radius: 100%;
  width: 81px;
  height: 81px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 2rem;
}

.image {
  border-radius: 100%;
  width: 73px;
  height: 73px;
  object-fit: cover;
}

.tag {
  position: absolute;
  right: -35px;
  color: white;
  text-transform: uppercase;
  font-size: 1.4rem;
  padding: 0.3rem 0.7rem;
  background-color: var(--textHighlight);
  border-radius: 15px;
  font-weight: 600;
}

.name {
  font-size: 1.8rem;
  font-weight: 600;
  color: #898989;
  margin-bottom: 3px;
}

.role {
  color: #515252;
  margin-bottom: 3rem;
}

.icon {
  width: 2rem;
  filter: invert(100%) sepia(6%) saturate(630%) hue-rotate(162deg) brightness(117%) contrast(100%);
}

.phoneContainer,
.emailContainer {
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.wrapperMargin {
  margin-bottom: 2rem;
}

.phoneContainer {
  background-color: #75dbcd;
}

.emailContainer {
  background-color: #028090;
}

.link {
  border: 1px solid white;
  border-radius: 0.5rem;
  padding: 0.8rem 1.5rem;
  display: inline-block;
  font-size: 1.4rem;
}

.main {
  margin-bottom: 19.6rem;
}

@media screen and (min-width: 768px) {
  .footer {
    display: flex;
    padding-top: 13.5rem;
  }
  .footer > div {
    width: 50%;
  }

  .main {
    margin: 0;
  }

}

@media screen and (min-width: 1024px) {
  .footer {
    display: block;
    padding-top: 0rem;
    padding-bottom: 5rem;
    width: 98rem;
    margin: auto;
  }

  .contactBlock {
    display: flex;
    align-items: center;
    gap: 6rem;
  }

  .wrapperMargin {
    margin: 0;
  }

}
