.map {
    margin-bottom: 3rem;
}

.imageContainer {
    border-radius: 1rem;
    overflow: hidden;
}

.container {
    border-radius: 1rem;
    overflow: hidden;
}