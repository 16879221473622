.appVersion {
    text-align: right;
    color: rgba(50, 50, 50, 1);
    font-size: 1rem;
}

.wrapper {
    position: absolute;
    bottom:-1.8rem;
    left: 1.6rem;
}

@media screen and (min-width:768px) {
    .wrapper {
        bottom:0;
    }
}

@media screen and (min-width:1024px) {
    .wrapper {
        bottom:-1.8rem;
    }
}
