
.containerRB {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    position: fixed;
    z-index: 99;
    top: 0;
    width: 100%;
    background-color: rgba(25, 34, 67, 0.9);
}

.wrapperRB {
    display: flex;
    align-items: center;
    gap: 4.5rem;
}

.imageRB {
    width: 5rem
}

.title {
    color: var(--textColorLight);
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.titleBold {
    font-weight: 700;
}

@media screen and (min-width:1024px) {
    .container {
        padding-left: 1.4rem;
    }
}

@media screen and (min-width:1280px) {
    .wrapper {
        width: 114rem;
        margin: auto;
    }
}