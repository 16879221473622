.app {
  background-color: var(--backgroundDark);
  color: var(--textColorLight);
  padding: 13rem 1.5rem 1.5rem;
  border-radius: 1rem;
  margin-bottom: 2.4rem;
  position: relative;
  max-width: 35.6rem;
}

.appHeader {
  font-size: 4.8rem;
  line-height: 4.8rem;
  font-weight: 300;
  margin-bottom: 0.7rem;
}

.appText {
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 1.8rem;
}

.link {
  border: 1px solid white;
  border-radius: 0.5rem;
  padding: 0.8rem 1.5rem;
  display: inline-block;
}

.containerMobile {
  position: absolute;
  top: -125px;
  z-index: 1;
  height: 244px;
}

.appDownloadLink {
  position: relative;
}

.imageOne {
  width: 12.1rem;
  max-width: unset;
  position: absolute;
  top: 0;
  z-index: 2;
}

.imageTwo {
  width: 10.5rem;
  max-width: unset;
  position: absolute;
  bottom: 0;
  z-index: 1;
  left: 105px;
}

.containerIcons {
  position: absolute;
  z-index: 5;
  top: 15px;
  right: 40px;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.imageThree, .imageFour {
  width: 9.5rem
}

@media screen and (min-width:768px) {
  .containerIcons {
    right: 50px;
  }

  .appText {
    width: 75%;
  }
}

@media screen and (min-width:1024px) {
  .containerIcons {
    right: 10px;
  }

  .appText {
    width: 85%;
  }

  .app {
    margin-top: 15rem;
    max-width: 31.6rem;
  }
}